import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('@/pages/home/Home')
const Category = () => import('@/pages/category/Category')
const Profile = () => import('@/pages/profile/Profile')
const LoginPage = () => import('@/pages/login/LoginPage')
const Login = () => import('@/pages/login/Login')
const Reg = () => import('@/pages/login/Reg')
const Message = () => import('@/pages/sub/profile/message/Message')
const Setting = () => import('@/pages/sub/profile/setting/Setting')
const Sug = () => import('@/pages/sub/profile/sug/Sug')
const MyFan = () => import('@/pages/sub/profile/myfan/MyFan')
const JiaoCheng = () => import('@/pages/sub/profile/jiaocheng/JiaoCheng')
const MyOrder = () => import('@/pages/sub/profile/myorder/MyOrder')
const MyCollect = () => import('@/pages/sub/profile/mycollect/MyCollect')
const Comfirm = () => import('@/pages/sub/profile/comfirm/Comfirm')
const Detail = () => import('@/pages/detail/Detail')
const Search = () => import('@/pages/search/Search')
const TiXian = () => import('@/pages/sub/profile/tixian/TiXian')
const TxRecord = () => import('@/pages/sub/profile/tixian/TxRecord')
const ShouYi = () => import('@/pages/sub/profile/shouyi/ShouYi')
const SzNc = () => import('@/pages/sub/profile/setting/SzNc')
const XgPsw = () => import('@/pages/sub/profile/setting/XgPsw')
const XgPayPsw = () => import('@/pages/sub/profile/setting/XgPayPsw')
const XgPhone = () => import('@/pages/sub/profile/setting/XgPhone')
const Share = () => import('@/pages/sub/profile/share/Share')
const YeDetail = () => import('@/pages/sub/profile/tixian/YeDetail')

const ZyDetail = () => import('@/pages/zydetail/Detail')
const ZyCart = () => import('@/pages/zydetail/Cart')
const ZyAddress = () => import('@/pages/zydetail/Address')
const ZyOrderAddress = () => import('@/pages/zydetail/OrderAddress')
const ZyOrder = () => import('@/pages/zydetail/Order')
const ZyPayOrder = () => import('@/pages/zydetail/PayOrder')
const ZySelectDomain = () => import('@/pages/zydetail/SelectDomain')
const ZyAddAddr = () => import('@/pages/zydetail/AddAddr')
const ZyEditAddr = () => import('@/pages/zydetail/EditAddr')
const ZySelectAddr = () => import('@/pages/zydetail/SelectAddr')
const ZyOrderList = () => import('@/pages/zydetail/OrderList')
const ZyOrderDetail = () => import('@/pages/zydetail/OrderDetail')
const ZyWriteRefund = () => import('@/pages/zydetail/WriteRefund')
const ZyRefundHandle = () => import('@/pages/zydetail/RefundHandle')
const ZyWriteLogistics = () => import('@/pages/zydetail/WriteLogistics')
const ZyRefundGoods = () => import('@/pages/zydetail/RefundGoods')
const ZyRefundSuccess = () => import('@/pages/zydetail/RefundSuccess')
const ZySpec = () => import('@/pages/zydetail/spec')
const ZyChangeServe = () => import('@/pages/zydetail/ChangeServe')
const ZyApplyRefund = () => import('@/pages/zydetail/ApplyRefund')
const ZyPay = () => import('@/pages/zydetail/Pay')
const ZyReApply = () => import('@/pages/zydetail/ReApply')
const ZyCancleRefund = () => import('@/pages/zydetail/CancleRefund')
const ZyApplyRefundEdit = () => import('@/pages/zydetail/ApplyRefundEdit')

const ZyMyOrderTurn = () => import('@/pages/zydetail/MyOrderTurn')

const LoginInviter = () => import('@/pages/login/LoginInviter')

const Promote = () => import('@/pages/happ/Promote')
const Orange = () => import('@/pages/happ/Orange')
const OrangeList = () => import('@/pages/happ/OrangeList')

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/category',
    component: Category
  },
  {
    path: '/profile',
    component: Profile
  },
  {
    path: '/loginpage',
    component: LoginPage
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/reg',
    component: Reg
  },
  {
    path: '/setting',
    component: Setting
  },
  {
    path: '/message',
    component: Message
  },
  {
    path: '/sug',
    component: Sug
  },
  {
    path: '/myfan',
    component: MyFan
  },
  {
    path: '/jiaocheng',
    component: JiaoCheng
  },
  {
    path: '/myorder',
    component: MyOrder
  },
  {
    path: '/mycollect',
    component: MyCollect
  },
  {
    path: '/comfirm',
    component: Comfirm
  },
  {
    path: '/detail',
    component: Detail
  },
  {
    path: '/search',
    component: Search
  },
  {
    path: '/tixian',
    component: TiXian
  },
  {
    path: '/txrecord',
    component: TxRecord
  },
  {
    path: '/shouyi',
    component: ShouYi
  },
  {
    path: '/sznc',
    component: SzNc
  },
  {
    path: '/xgpsw',
    component: XgPsw
  },
  {
    path: '/xgpaypsw',
    component: XgPayPsw
  },
  {
    path: '/xgphone',
    component: XgPhone
  },
  
  {
    path: '/share',
    component: Share
  },
  {
    path: '/yedetail',
    component: YeDetail
  },
  {
    path: '/zydetail',
    component: ZyDetail
  },
  {
    path: '/zycart',
    component: ZyCart
  },
  {
    path: '/zyaddr',
    component: ZyAddress
  },
  {
    path: '/zyorderaddr',
    component: ZyOrderAddress
  },
  {
    path: '/zyorder',
    component: ZyOrder
  },
  {
    path: '/zypayorder',
    component: ZyPayOrder
  },
  {
    path: '/zyselectdomain',
    component: ZySelectDomain
  },
  
  {
    path: '/zyaddaddr',
    component: ZyAddAddr
  },
  {
    path: '/zyeditaddr',
    component: ZyEditAddr
  },
  {
    path: '/zyselectaddr',
    component: ZySelectAddr
  },
  {
    path: '/zyorderlist',
    component: ZyOrderList
  },
  {
    path: '/zyorderdetail',
    component: ZyOrderDetail
  },
  {
    path: '/zyrefundhandle',
    component: ZyRefundHandle
  },
  {
    path: '/zywriterefund',
    component: ZyWriteRefund
  },
  {
    path: '/zywritelogistics',
    component: ZyWriteLogistics
  },
  {
    path: '/zyrefundgoods',
    component: ZyRefundGoods
  },
  {
    path: '/zyrefundsuccess',
    component: ZyRefundSuccess
  },
  { 
    path: '/zyspec',
    component: ZySpec
  },
  { 
    path: '/zychangeserve',
    component: ZyChangeServe
  },
  { 
    path: '/zyapplyrefund',
    component: ZyApplyRefund
  },
  { 
    path: '/zypay',
    component: ZyPay
  },
  { 
    path: '/zyreapply',
    component: ZyReApply
  },
  { 
    path: '/zycanclerefund',
    component: ZyCancleRefund
  },
  { 
    path: '/zyapplyrefundedit',
    component: ZyApplyRefundEdit
  },
  { 
    path: '/zymyorderturn',
    component: ZyMyOrderTurn
  },
  { 
    path: '/loginInviter',
    component: LoginInviter
  },
  { 
    path: '/promotions',
    component: Promote
  },
  { 
    path: '/orange',
    component: Orange
  },
  { 
    path: '/orangelist',
    component: OrangeList
  },
  
]


const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
