import Vue from 'vue'
import Vuex from 'vuex'
/*
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
*/
//1安装插件
Vue.use(Vuex)
//2创建对象
const store = new Vuex.Store({
    state: {
        
    }
})

export default store