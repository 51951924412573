<template>
  <div id="app">
    <router-view v-if="!$route.meta.keepAlive"/>
    <main-tab-bar class="nav-bottom" :class="{iphonex:isIphoneX}" v-if="footShow" />
  </div>
</template>

<script>
  import MainTabBar from 'components/content/mainTabbar/MainTabBar'

  export default {
    name: 'app',
    data(){
      return {
        footShow: true,
        isIphoneX:window.screen.height > 750?true:false
      }
    },
    components: {
      MainTabBar
    },
    mounted() {
      
    },
    watch: {//监听路由变化
      $route(to, from) {
        let thisPage = to.path
        if(thisPage === '/home' || thisPage === '/category' || thisPage === '/zycart' || thisPage === '/profile'){
          this.footShow = true
        }else {
          this.footShow = false
        }
      }
    }
  }
</script>

<style>
  @import 'assets/css/base.css';
  .nav-bottom{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .iphonex{
    padding-bottom: .266667rem;
  }
</style>
