<template>
  <div class="toast" v-show="isShow">
    <div>{{message}}</div>
  </div>
</template>

<script>

	export default {
		name: "Toast",
    data() {
      return {
        message: '',
        isShow: false
      }
    },
    methods: {
		  show(message, duration = 1000) {
        this.isShow = true;
        this.message = message;
        setTimeout(() =>{
          this.isShow = false;
          this.message = '';
        }, duration);
      }
    },
    
	}
</script>

<style scoped>
.toast{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 14px;
  color: #fff;
  background-color: rgba(0, 0, 0, .6);
  z-index: 999;
  font-size: 14px;
  border-radius: .16rem;
}
</style>
