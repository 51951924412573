<template>
    <tab-bar>
      <tab-bar-item path="/home">
        <img slot="item-icon" src="~assets/img/tabbar/shouye_not.png" alt="">
        <img slot="item-icon-active" src="~assets/img/tabbar/shouye_on.png" alt="">
        <div slot="item-text">首页</div>
      </tab-bar-item>
      <tab-bar-item path="/category">
        <img slot="item-icon" src="~assets/img/tabbar/shequ_not.png" alt="">
        <img slot="item-icon-active" src="~assets/img/tabbar/shequ_on.png" alt="">
        <div slot="item-text">分类</div>
      </tab-bar-item>
      <tab-bar-item path="/zycart">
        <img slot="item-icon" src="~assets/img/tabbar/gouwuche_not.png" alt="">
        <img slot="item-icon-active" src="~assets/img/tabbar/gouwuche_on.png" alt="">
        <div slot="item-text">购物车</div>
      </tab-bar-item>
      <tab-bar-item path="/profile">
        <img slot="item-icon" src="~assets/img/tabbar/wode_not.png" alt="">
        <img slot="item-icon-active" src="~assets/img/tabbar/wode_on.png" alt="">
        <div slot="item-text">我的</div>
      </tab-bar-item>
    </tab-bar>
</template>

<script>
import TabBar from 'components/common/tabbar/TabBar'
import TabBarItem from 'components/common/tabbar/TabBarItem'

export default {
  name: 'MainTabBar',
  components: {
      TabBar,
      TabBarItem
  }
}
</script>

<style scoped>
    
</style>
