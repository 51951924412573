import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'common/index'
import toast from 'components/common/toast'
import store from './store'
import directives from './directives'
import 'vant/lib/index.css';


Object.keys(directives).forEach(item => {
  // console.log( directives[item])
  Vue.directive(item, directives[item])
})
Vue.config.productionTip = false

Vue.use(toast)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
