<template>
  <div class="" id="tab-bar">
      <slot></slot>
      <!--
    <div class="tab-bar-item">
        <img src="../../assets/img/tabbar/shouye_not.jpg" alt="">
        <div>首页</div>
    </div>
      <div class="tab-bar-item">
        <img src="../../assets/img/tabbar/shequ_not.jpg" alt="">
        <div>分类</div>
      </div>
      <div class="tab-bar-item">
        <img src="../../assets/img/tabbar/wode_not.jpg" alt="">
        <div>我的</div>
      </div>-->
  </div>
</template>

<script>
export default {
  name: 'TabBar'
}
</script>

<style scoped>
    #tab-bar {
        display: flex;
        background-color: #fff;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        
    }
    
</style>
